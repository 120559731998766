import { createRouter, createWebHistory } from "vue-router";
import { useCounterStore } from "@/store/index.js";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  routes: [
    {
      name: "Auth",
      path: "/",
      component: () => import("@/views/Pages/index"),
      children: [
        {
          name: "Login",
          path: "",
          component: () => import("@/views/Pages/login"),
        },
        {
          name: "auth",
          path: "auth",
          component: () => import("@/views/Pages/auth"),
        },
      ],
    },
    {
      path: "/admin",
      component: () => import("@/views/Dashboard/index"),
      beforeEnter: (to, from, next) => {
        const token = localStorage.getItem("accessToken");

        if (token) {
          next();
        } else {
          next({
            name: "Login",
          });
        }
      },
      children: [
        // Dashboard
        {
          name: "الاحصائيات",
          path: "dashboard",
          component: () => import("@/views/Dashboard/Home/dashboard"),
        },
        {
          name: "الملف الشخصي",
          path: "profile",
          component: () => import("@/views/Dashboard/profile/profile"),
        },
        {
          name: "المستخدمين",
          path: "users",
          component: () => import("@/views/Dashboard/Users/users"),
        },
        {
          name: "سجل التدقيق",
          path: "Archives",
          component: () => import("@/views/Dashboard/Archives/Archives"),
        },
        {
          name: "البحث حسب رقم البطاقة التموينية القديم",
          path: "searchWithOldNum",
          component: () =>
            import("@/views/Dashboard/searchWithOldNum/searchWithOldNum"),
        },
        {
          name: "معاينة تفاصيل الاستمارة",
          path: "preview/:id",
          props: true,
          component: () => import("@/views/Dashboard/Audit/preview/preview"),
        },
        {
          name: "معاينة الاستمارة",
          path: "openForm/:id",
          props: true,
          component: () => import("@/views/Dashboard/Archives/openForm"),
        },
        {
          name: "معاينة تفاصيل رب الاسرة",
          path: "transaction/:id",
          props: true,
          component: () =>
            import("@/views/Dashboard/Audit/transaction/transaction"),
        },
        {
          name: "معاينة تفاصيل افراد العائلة",
          path: "transFamily/:id",
          props: true,
          component: () =>
            import("@/views/Dashboard/Audit/transaction/transFamily"),
        },
        {
          name: "قائمة العوائل المسجلة ",
          path: "pendingListCitizens",
          component: () =>
            import("@/views/Dashboard/listCitizens/pendingListCitizens"),
        },
        {
          name: "قائمة العوائل الدققة ",
          path: "acceptedListCitizens",
          component: () =>
            import("@/views/Dashboard/listCitizens/acceptedListCitizens"),
        },
        {
          name: "قائمة العوائل المرفوضة ",
          path: "rejectedListCitizens",
          component: () =>
            import("@/views/Dashboard/listCitizens/rejectedListCitizens"),
        },
        {
          name: "اعدادات التطبيق",
          path: "setting",
          component: () => import("@/views/Dashboard/setting/setting"),
        },
        {
          name: "الاستفسارات",
          path: "faqList",
          component: () => import("@/views/Dashboard/faq/faqList"),
        },
      ],
    },
    {
      path: "/:catchAll(.*)",
      name: "error",
      component: () => import("@/views/Pages/index"),
      children: [
        {
          name: "404 Error",
          path: "",
          component: () => import("@/views/Pages/errorPage"),
        },
      ],
    },
  ],
});

export default router;
