<template>
  <router-view />
</template>

<script setup>
import Axios from "axios";
import { useCounterStore } from "@/store/index.js";
const store = useCounterStore();

if (store.accessToken) {
Axios.defaults.headers.common["Authorization"] = `Bearer ${store.accessToken}`;
}
</script>
<style lang="scss">

.v-btn {
  letter-spacing: 0 !important;
}
@font-face {
  font-family: "somer";
  src: local("somer"),
    url(./assets/fonts/TTF/Standard/SomarSans-Regular.ttf) format("truetype");
}
* {
  font-family: "somer";
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #313942;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1fa35e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1fa35e;
}
</style>
