import { defineStore } from "pinia";
import router from "@/router/index";
import axios from "axios";

export const useCounterStore = defineStore("counter", {
  state: () => ({
    loading: false,
    rail: true,
    drawer: true,
    userInfo: JSON.parse(localStorage.getItem("userInfo")),
    refreshToken: localStorage.getItem("refreshToken"),
    accessToken: localStorage.getItem("accessToken"),
    cardInfo: JSON.parse(localStorage.getItem("cardInfo")),
    loginIndex: 0,
    token: null,
    isDark: localStorage.getItem("isDark"),
    refreshData: false,
  }),
  getters: {
    isRail(state) {
      if (state.rail === true) return true;
      return false;
    },
  },
  actions: {
    setFunctionInChildB(func) {
      this.someFunctionInChildB = func;
    },
    callFunctionInChildB() {
      if (this.someFunctionInChildB) {
        this.someFunctionInChildB();
      } else {
        console.error("Function in Child B not available");
      }
    },
    railChange() {
      this.rail = !this.rail;
    },
    drawerChange() {
      this.drawer = !this.drawer;
    },
    login(data) {
      localStorage.setItem("accessToken", data.token);
      localStorage.setItem("refreshToken", data.refreshToken);
      this.accessToken = data.token;
      this.refreshToken = data.refreshToken;
      axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
      axios
        .get("employee/self")
        .then((res) => {
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          this.userInfo = JSON.parse(localStorage.getItem("userInfo"));

          if (
            this.userInfo.roles.includes("SUPERADMIN") ||
            this.userInfo.roles.includes("MONITOR")
          ) {
            router.push("/admin/dashboard");
          } else if (this.userInfo.roles.includes("SUPPORT")) {
            router.push("/admin/searchWithOldNum");
          } else if (this.userInfo.roles.includes("REVIEWER")) {
            router.push("/admin/pendingListCitizens");
          } 
        })
        .catch((err) => {
          console.error(err);
        });
    },
    logout() {
      localStorage.clear();
      this.accessToken = null;
      this.refreshToken = null;
      this.token = null;
      this.loginIndex = 0;
      delete axios.defaults.headers.common['Authorization'];
      router.push("/");
    },
  },
});
