import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import i18n from "./i18n";
import axios from "axios";
import VueAxios from "vue-axios";
import { vsxIcon } from "vue-iconsax";
import { MotionPlugin } from "@vueuse/motion";
import { GesturePlugin } from "@vueuse/gesture";
import VueApexCharts from "vue3-apexcharts";
import vuetify from "./plugins/vuetify";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import VueTheMask from "vue-the-mask";
import Loading from "./components/loading.vue";
import { useCounterStore } from "./store/index";
import "./registerServiceWorker";
import JsonExcel from "vue-json-excel3";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

let isRefreshing = false;
let refreshSubscribers = [];

function onRefreshed(token) {
  refreshSubscribers.forEach((subscriber) => subscriber(token));
  refreshSubscribers = [];
}
function addRefreshSubscriber(subscriber) {
  refreshSubscribers.push(subscriber);
}

axios.defaults.baseURL = process.env.VUE_APP_URL;
axios.interceptors.response.use(
  function (response) {
    if (response.status === 201) {
      toast("تمت العملية بنجاح", {
        rtl: true,
        type: "success",
      });
    }

    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      const store = useCounterStore();
      const config = error.config;
      const originalRequest = config;

      if (!isRefreshing) {
        isRefreshing = true;
        axios
          .post(
            "auth/refresh_token",
            {},
            {
              headers: {
                "X-Token-TOTP": localStorage.getItem("refreshToken"),
              },
            }
          )
          .then((res) => {
            localStorage.setItem("accessToken", res.data.data.token);
            localStorage.setItem("refreshToken", res.data.data.refreshToken);
            store.refreshToken = res.data.data.refreshToken;
            store.accessToken = res.data.data.token;

            isRefreshing = false;
            onRefreshed(res.data.data.token);
          })
          .catch((err) => {
            toast("انتهت صلاحية الجلسة", {
              rtl: true,
              type: "error",
            });
            router.push("/");
            isRefreshing = false;
          });
        return new Promise((resolve, reject) => {
          addRefreshSubscriber((token) => {
            // config.headers.Authorization = `Bearer ${token}`;
            resolve(axios.request(config));
          });
        });
      }
      // router.push('/')
    } else {
      toast(error.response.data.locale, {
        rtl: true,
        type: "error",
      });
    }

    // else if (error.response.status === 400 || error.response.status === 404 ) {
    //   toast(error.response.data.locale, {
    //     rtl: true,
    //     type: "error",
    //   });
    // } else if (error.response.status === 429) {
    //   toast("الكثير من المحاولات , يرجى المحاولة بعد ساعة", {
    //     rtl: true,
    //     type: "error",
    //   });
    // } else if (error.response.status === 500 || error.response.status === 501) {
    //   toast("الكثير من الطلبات على الخادم يرجى المحاولة في وقت اخر", {
    //     rtl: true,
    //     type: "error",
    //   });
    // }

    return Promise.reject(error);
  }
);

createApp(App)
  .component("vsxIcon", vsxIcon)
  .component("EasyDataTable", Vue3EasyDataTable)
  .component("VueDatePicker", VueDatePicker)
  .component("loading", Loading)
  .component("downloadExcel", JsonExcel)
  .use(GesturePlugin)
  .use(MotionPlugin)
  .use(VueAxios, axios)
  .use(i18n)
  .use(createPinia())
  .use(router)
  .use(VueApexCharts)
  .use(vuetify)
  .use(VueTheMask)
  .mount("#app");
